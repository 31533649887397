import React from 'react';
import { Container } from 'ui/component/container';
import { Breadcrumbs } from 'ui/component/breadcrumbs';
import { connectCMSContent } from '@silkpwa/module/react-component/connect-cms-content';
import { MainColumn } from 'ui/component/main-column';
import { DocumentTitle } from '@silkpwa/module/react-component/document-title';
import styles from './style.css';

const CMSPageImpl = ({ title, crumbs }) => (
    <div>
        <DocumentTitle>{title}</DocumentTitle>
        <Breadcrumbs path={crumbs} />
        <MainColumn className="maincolumn-cmspage">
            <div className={styles.cmsPage}>
                <h1>{title}</h1>
                <Container id="content" />
            </div>
        </MainColumn>
    </div>
);

const CMSPage = connectCMSContent(CMSPageImpl);

export { CMSPage as default };
